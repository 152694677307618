import axios from 'axios'

const config = {
    // baseURL: 'http://api.robot.enercred.com.br:4000/cemig_search/',
    // baseURL: 'http://localhost:4000/',
    headers: {
        'Access-Control-Allow-Origin':'*'
    }
}

// console.log('data: ', data)
  // console.log('VUE_APP_CEP_API: ', process.env.VUE_APP_CEP_API)
export const getCPFCemig = (cpf) => axios.get(process.env.VUE_APP_GETCEMIG_API + cpf, config);