<template>
  <v-container grid-list-md text-xs-center class="fundo" fluid>
    <v-layout row wrap>
      <!-- LOGOTIPO -->
      <v-flex xl12 lg12 md12 sm12 xs12 class="cabecalho">
        <img
          src="../../assets/logo.svg"
          width="200px"
          alt="Enercred - Energia Limpa e Barata"
        />
      </v-flex>
      <v-flex xl6 lg6 md6 sm12 xs12 style="background: #FFF">
        <v-container grid-list-md text-xs-center fluid>
          <v-layout align-center justify-space-around row fill-height>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-flex xs12>
                Informe o CPF <a style="font-weight: 900">do titular da conta</a>.
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  style="width: 100%"
                  type="tel"
                  v-model="cpf"
                  autofocus
                  label="CPF"
                  prepend-inner-icon="assignment_ind"
                  mask="###.###.###-##"
                  name="cpf"
                  :rules="[
                    v => !!v || 'Por favor, informe o CPF do titular da conta!',
                    v => this.validaCPF(this.cpf) === true || 'CPF inválido'
                  ]"
                  required
                  outline
                  append-icon="search"
                  @click:append="buscaDadosComplementares(cpf)"
                  @change="buscaDadosComplementares($event)"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm6 md4>
                <v-btn color="#0d67d0" :loading="loading_cpf" :disabled="!valid || loading_cpf" @click.prevent="buscaDadosComplementares(this.cpf)">OK</v-btn>
              </v-flex> -->
              <v-flex xl12 lg12 md12 sm12 xs12>
                <v-card
                  color="white"
                  v-if="loading_cpf"
                  flat
                >
                  <v-card-text>
                  {{label_loading_cpf}}
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    class="mb-0"
                  ></v-progress-linear>
                  </v-card-text>
                </v-card>
                <v-card v-if="ucs_cemig.length > 0" flat>
                  <v-card-title>
                    Selecione as unidades que você quer receber créditos.
                  </v-card-title>
                  <v-card-text>
                    <v-list
                      subheader
                      three-line
                    >
                      <!-- <v-subheader>Selecione as unidades consumidoras que o cliente quer contratar.</v-subheader> -->
                      <v-list-tile v-for="(uc, idx) in ucs_cemig"  v-bind:key="uc.installation_number">
                        <v-list-tile-action>
                        <v-checkbox
                          v-model="ucs_chbox"
                          :value="uc.installation_number"
                          :color="colors_uc[idx]"
                          required
                        ></v-checkbox>
                        </v-list-tile-action>

                        <v-list-tile-content>
                        <v-list-tile-title>{{uc.installation_number}}</v-list-tile-title>
                        <v-list-tile-sub-title>{{uc.address}}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-divider v-if="idx + 1 < items.length" :key="`divider-${idx}`"></v-divider>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                  <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="dialog_ucs = false">fechar</v-btn>
                    <v-btn color="blue darken-1" @click="ucs_selected()">Selecionar</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-flex>
              <v-flex xl12 lg12 md12 sm12 xs12>
                <!-- <v-btn v-if="ucs_cemig.length > 0" class="btn-assinar" block round color="secondary" :loading="loading_cpf" :disabled="!valid || loading_cpf || ucs_chbox.length === 0"  @click.prevent="validate">Continuar ></v-btn> -->
                <v-btn
                    v-if="ucs_cemig.length > 0"
                    color="secondary"
                    block
                    large
                    :loading="loading_cpf"
                    :disabled="!valid || loading_cpf || ucs_chbox.length === 0"  
                    @click.prevent="validate"
                    type="submit"
                  >CONTINUAR</v-btn>
              </v-flex>
            </v-form>
            <!-- <cpnPasso2Vantagens/> -->
            <!-- <v-btn class="btn-assinar" block round color="secondary"  @click.prevent="next">Assinar</v-btn> -->
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex  xl6 lg6 md6 sm12 xs12 class="hidden-sm-and-down">
        <v-layout align-center justify-center row wrap>
          <img src="../../assets/beneficios/passo3.jpg" width="85%"/>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-snackbar
      :color="color"
      :bottom="bottom"
      :top="top"
      :left="left"
      :right="right"
      v-model="snackbar"
      :timeout="timeout"
      dark
    >
      <v-icon
      color="white"
      class="mr-3"
      >
      mdi-bell-plus
      </v-icon>
      <div v-html="snackMessage"/>
      <v-icon
      size="16"
      @click="snackbar = false"
      >
      mdi-close-circle
      </v-icon>
    </v-snackbar>
  </v-container>
</template>
<script>
import { saveAnalytics } from "@/services/save_analytics"
import { GET_PEOPLE } from "../../graphql/people"
// import { SET_CONVERSION } from "../../graphql/rdstation"
import { getLead, updateLead } from '../../services/firebase/lead'
import { getCPFCemig } from '../../services/getCPFCemig'
import { getPEOPLE } from '../../services/getPeople'
import moment from 'moment'

export default {
  components: {
    // cpnPasso2Economia: Passo2Economia,
    // cpnPasso2Vantagens: Passo2Vantagens,
  },
  data() {
    return {
      loading_inicio: true,
      loading: false,
      label_loading_cpf: '',
      loading_cpf: false,
      valid: false,
      isProd: false,
      cpf: '',
      nome_titular: '',
      mother_name: '',
      nacionalidade: '',
      lead: {},
      ucs_cemig: [],
      ucs_chbox: [],
      items: [],
      color: null,
			colors: [
					'purple',
					'info',
					'success',
					'warning',
					'error'
      ],
      colors_uc: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange','info',
					'success',
					'warning',
          'error'
      ],
			top: true,
			bottom: false,
			left: false,
      right: false,
      timeout: 36000,
			snackMessage: '',
			snackbar: false,
    }
  },
  created() {
    this.loading_inicio = true
    this.isProd = process.env.NODE_ENV === "production"
    if(this.$route.query.email) {
      const email = this.$route.query.email
      getLead(email)
        .then(lead => {
          this.lead = lead
          this.cpf = lead.data().cpf
          this.items = lead.data().installation_numbers || []
          this.loading = false
          this.loading_inicio = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading = false
          this.loading_inicio = false
        })
    } else {
      const lead = JSON.parse(localStorage.getItem("lead"))
      // console.log('lead: ', lead)
      if(lead && lead.email) {
        this.lead = lead
        this.cpf = lead.cpf
        this.items = lead.installation_numbers || []
      }
      this.loading = false
      this.loading_inicio = false
    }
  },
  methods: {
    async validate() {
      // console.log('ucs_chbox: ', this.ucs_chbox)
      if (this.$refs.form.validate() && this.lead && this.lead.name && this.lead.email) {
        let peopleGender = this.lead.gender || ''
        let peopleBirthdate = this.lead.date_of_birth || ''
        let peopleDocument = this.lead.ID_document || ''
        this.loading = true
        if((!this.date_of_birth && !this.gender && !this.ID_document) || this.date_of_birth === '' || this.gender === '') {
          await getPEOPLE(this.cpf)
              .then(result => {
                if(result.data.BasicData) {
                  this.nome_titular = result.data.BasicData.Name || '',
                  peopleGender = result.data.BasicData.Gender || ''
                  this.nacionalidade = result.data.BasicData.BirthCountry || 'brasileiro(a)'
                  peopleDocument = result.data.BasicData.AlternativeIdNumbers.RG || ""
                  this.mother_name = result.data.BasicData.MotherName || ''
                  peopleBirthdate = this.formatBirthday(result.data.BasicData.BirthDate) || ''
                }
                this.dialog_cpf = false
              })
              .catch(async error => {
                console.error(error)
                const data = {
                  cpf: this.cpf,
                  step: 13
                }
                await this.save(data)
              })
        }
        const data = {
          cpf: this.cpf,
          nome_titular: this.nome_titular || '',
          nacionalidade: this.nacionalidade || 'Brasileiro(a)',
          mother_name: this.mother_name || '',
          gender: peopleGender || '',
          date_of_birth: peopleBirthdate || '',
          ID_document: peopleDocument || '',
          installation_numbers: this.ucs_chbox || [],
          step: 13
        }
        
        await this.save(data)
      }
    },
    async save(data) {
      await updateLead(this.lead.email, data)
          .then(async () => {
            let new_lead = {
              ...this.lead,
              ...data,
            }
            localStorage.setItem("lead", JSON.stringify(new_lead))
            if (this.isProd) {
              const analytics = {
                eventCategory: "onboard",
                eventAction: "SELECTED-UCS",
                eventLabel: "enercred",
                transaction_id: this.lead.email,
                value: this.lead.average_consumption,
                utm_source: this.lead.utm_source,
                utm_medium: this.lead.utm_medium,
                utm_campaign: this.lead.utm_campaign,
                utm_content: this.lead.utm_content,
                utm_term: this.lead.utm_term,
              }
              await saveAnalytics(analytics)
              // const conversion = {
              //   event_type: "OPPORTUNITY",
              //   event_family: "CDP",
              //   payload: {
              //     email: this.lead.email,
              //     funnel_name: "default"
              //   }
              // }
              // await this.setRDConversion(conversion)
            }
            this.loading = false
            this.loading_cpf = false
            await this.$router.push("/assinatura/4")
          })
          .catch(err => {
            this.loading = false
            this.loading_cpf = false
            console.error("Error: ", err)
            this.snack('Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!', 'red', 'top')
            // alert("Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!")
            this.$router.push("/assinatura/2")
          })
    },
    async buscaDadosComplementares(cpf) {
      if(cpf && cpf !== '' && this.validaCPF(cpf)) {
        this.loading_cpf = true
        this.label_loading_cpf = 'Buscando dados na CEMIG...'
        // console.log('busca CPF na Cemig')
        await getCPFCemig(cpf)
          .then(ret => {
            if(ret.status === 200) {
              this.ucs_cemig = ret.data
              ret.data.forEach((data, idx) => {
                const item_uc = {
                  text: data.installation_number,
                  color: this.colors[idx] || '#000000'
                }
                this.items.push(item_uc)
              })
              this.loading_cpf = false
            } else {
              this.loading_cpf = false
              this.$router.push("/assinatura/4")
              console.error('Atenção: CPF não encontrado no site da CEMIG, validar se o CPF informado é do titular da conta.')
            }
          })
          .catch(err => {
            // this.dialog_ucs = true
            console.error(err)
            // this.snack('Erro ao buscar dados do cliente na CEMIG. Valide os dados com o cliente para continuar. ', 'warning', 'bottom')
            // this.snack('Atenção: CPF não encontrado no site da CEMIG, validar se o CPF informado é do titular da conta.', 'warning', 'bottom')
            this.validate()
            // this.$router.push("/assinatura/4")
          })
        this.label_loading_cpf = 'Buscando dados complementares do cliente...'
			}
    },
    async getPeople(cpf) {
      return await this.$apollo.query({
        query: GET_PEOPLE,
        variables: {
          doc: cpf
        }
      })
    },
    // setRDConversion(conversion) {
    //   return this.$apollo.mutate({
    //     // Query
    //     mutation: SET_CONVERSION,
    //     // Parameters
    //     variables: {
    //       event_type: conversion.event_type,
    //       event_family: conversion.event_family,
    //       payload: conversion.payload
    //     }
    //   })
    // },
    validaCPF(strCPF) {
      if (!strCPF) return ""
      var Soma
      var Resto
      Soma = 0
      if (strCPF == "00000000000") return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(10, 11))) return false
      return true
    },
    formatBirthday(value) {
      // console.log(new Date(value))
      if(!value || value === '') return ''
      else {
        if(typeof(value) === 'string') return moment.utc(new Date(value)).format('DD/MM/YYYY')
        if(typeof(value) === 'object') return moment.utc(value.toDate()).format('DD/MM/YYYY')
        // let date = new Date(value)
        // console.log(date)
        // date = moment(date).format('DD/MM/YYYY')
        // console.log(date)
        // return date
      }
    },
    snack (message, color, ...args) {
			this.top = false
			this.bottom = false
			this.left = false
			this.right = false

			for (const loc of args) {
					this[loc] = true
			}

			this.color = color
			this.snackMessage = message
			this.snackbar = true
    },
  },
  mounted() {
    this.loading_inicio = false
  }
}
</script>

<style scoped>
.fundo {
  background-color: #FFF;
  height: 100vh;
}
.btn-assinar {
  /* margin: 10px 20px 10px 20px; */
  font-weight: 900;
  font-size: 1.1rem;
}
.cabecalho {
  height: 70px;
  vertical-align: middle;
  display: table-cell;
  margin-top: 20px;
}
</style>
