// import Http from './http'; // não precisa de .js
import axios from 'axios'

// const token = !localStorage.getItem('token')?false:localStorage.getItem('token')
// const token = localStorage.getItem('token')

// if(token) {
//   axios.defaults.headers.get['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.get['Content-Type'] = 'application/json'
// }

const config = {
  
  headers: {
    // 'Authorization': 'Bearer ' + token, 
    'Access-Control-Allow-Origin':'*'
  }
}

export const getPEOPLE = (data) => {
  // console.log('data: ', data)
  // console.log('VUE_APP_DADOSCOMPLEMENTARES_API: ', process.env.VUE_APP_DADOSCOMPLEMENTARES_API)
  return axios.get(process.env.VUE_APP_DADOSCOMPLEMENTARES_API + '?cpf=' + data, config)
}

// function getToken() {
//   firebaseAuth.currentUser.getIdToken(true)
//     .then(idToken => {
//       return idToken
//     })
//     .catch(err => {
//       console.log('Erro ao pegar token: ', err)
//       return null
//     })
// }